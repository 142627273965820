import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import AboutImg from '../Image/AboutImg';
import footerData from '../../mock/footer.json';

const imgRegEx = /(\w+\.\w+)/g;

const { email, phone_Number, fbLink, instaLink, logo, alt } = footerData;

const linkToMail = `mailto:${email}`;
const linkToWhatsapp = `https://api.whatsapp.com/send?phone=${phone_Number}`;
const linkToPhoneNumber = `tel:+${phone_Number}`;

const Footer = ({ linkTo }) => {
  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <Row className="footer-row">
          <Col className="footer-flex" md={4}>
            <div>
              <p className="footer__text">
                Our contacts:
                <br />
                <a rel="noopener noreferrer" href={linkToMail} target="_blank">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  {email}
                </a>
                <br />
                <a rel="noopener noreferrer" href={linkToWhatsapp} target="_blank">
                  <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  {phone_Number}
                </a>
              </p>
            </div>
            <div>
              <hr />
              <p className="footer__text">
                All Rights Reserved{' '}
                <Link
                  id="dev-by"
                  to="/about-us/#staffDanylo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Danylo Hips
                </Link>{' '}
                <br />
                Copyright © 2009 - {new Date().getFullYear()}
              </p>
            </div>
          </Col>
          <Col className="footer-flex" md={4}>
            <span className="back-to-top">
              <Link to={linkTo}>
                <i className="fa fa-angle-up fa" aria-hidden="true" />
              </Link>
            </span>
            <Link to={linkTo}>
              <AboutImg filename={logo.match(imgRegEx)} alt={alt} />
            </Link>
            <br />
            <span id="privacy-footer">
              Our {' - '} <Link to={'/privacy-policy'}> Privacy Policy </Link>
              <br />
              <br />
            </span>
          </Col>
          <Col className="footer-flex" md={4}>
            <div>
              <p className="footer__text">
                Follow us:
                <br />
                <span id="share-buttons">
                  <a href={fbLink} target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                  </a>
                  <a href={instaLink} target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://www.tiktok.com/@danylohips28"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="15px"
                      height="20px"
                      viewBox="0 0 100 125"
                      version="1.1"
                      xmlSpace="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g id="Layer_1" />

                      <g>
                        <g>
                          <path
                            fill="#fdfdfd"
                            d="M74.089,21.254c-4.121-4.791-6.28-10.981-6.108-17.283L52.582,3.599c0,0,0,0.657,0,1.543v61.751    c0.215,19.536-30.651,19.062-28.209-2.532c1.493-8.586,10.719-13.87,18.917-10.883c0,0,0-15.712,0-15.712    C25.442,34.658,8.528,48.864,8.706,66.962c1.56,39.25,57.856,39.255,59.418,0c-0.393-1.405-0.173-27.561-0.229-29.767    c7.002,4.357,15.149,6.535,23.4,6.256V27.224C83.667,27.224,77.846,25.196,74.089,21.254z"
                          />
                        </g>
                      </g>
                    </svg>
                  </a>
                </span>
              </p>
            </div>
            <div>
              <hr />
              <p className="footer__text">
                Developed by{' '}
                <a
                  id="dev-by"
                  href="https://www.linkedin.com/in/pavloomelianchuk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pavlo Omelianchuk
                </a>
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="footer-contacts-wrapper">
            <a href={linkToPhoneNumber} className="col-xs-3" rel="noopener noreferrer">
              <span className="fa fa-2x fa-mobile-phone"></span>
            </a>
            <a
              href={linkToWhatsapp}
              data-action="share/whatsapp/share"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="fa fa-2x fa-whatsapp"></span>
            </a>
            <a href={fbLink} className="col-xs-3" rel="noopener noreferrer" target="_blank">
              <span className="fa fa-2x fa-facebook"></span>
            </a>
            <a href={linkToMail} rel="noopener noreferrer" target="_blank">
              <span className="fa fa-2x fa-at"></span>
            </a>
            <a
              href="https://www.tiktok.com/@danylohips28"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 100 100"
                version="1.1"
                xmlSpace="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g id="Layer_1" />

                <g>
                  <g>
                    <path
                      fill="#3cd1c0"
                      d="M74.089,21.254c-4.121-4.791-6.28-10.981-6.108-17.283L52.582,3.599c0,0,0,0.657,0,1.543v61.751    c0.215,19.536-30.651,19.062-28.209-2.532c1.493-8.586,10.719-13.87,18.917-10.883c0,0,0-15.712,0-15.712    C25.442,34.658,8.528,48.864,8.706,66.962c1.56,39.25,57.856,39.255,59.418,0c-0.393-1.405-0.173-27.561-0.229-29.767    c7.002,4.357,15.149,6.535,23.4,6.256V27.224C83.667,27.224,77.846,25.196,74.089,21.254z"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
